<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="crmTodos"
                          base-table="todos"
                          columnsPrefix="common.column.crm_todos."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.title.crm_todos"
                          :actions-list="getActions()"
                >
                    <template #cell(id)="row">
                        <a href="#"
                           @click.prevent="$root.$children[0].openModal('todo-modal',{id:row.item.id}, refreshTable)">{{
                                row.item.id
                            }}</a>
                    </template>

                    <template #cell(deadline)="row">
                        {{ row.item.deadline | formatDate('DD.MM.YYYY') }}
                    </template>

                    <template #cell(contract_id)="row">
                        <a href="#" @click.prevent="customerContractUrl(row.item.contract_id)">{{
                                row.item.contract_id
                            }}</a>
                    </template>

                    <template #cell(kva_id)="row">
                        <a @click.prevent="$root.$children[0].openModal('kva-modal', row.item.kva_id.id)"
                           href="#">{{ row.item.kva_id.name }}</a>
                    </template>

                    <template #cell(customer_id)="row">
                        <a href="#"
                           @click.prevent="openCustomer(row.item.customer_id.id)">
                            {{ row.item.customer_id.name }} - {{row.item.customer_id.id}}
                        </a>
                    </template>

                    <template #cell(total)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="$root.$children[0].openModal('todo-modal',{id:row.item.id}, refreshTable)">
                                <font-awesome-icon class="mr-2" icon="edit"/>
                                {{ $t('common.title.edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="deleteTodo(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.title.delete') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'CrmTodos',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Crm', ['getTodosTable']),
        ...mapGetters('CommonData', ['getWorkflowTriggers']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Crm/fetchTodos', 'getTodosTable');
        },
        customerContractUrl(file) {
            this.$root.openDocument(file, 'customer_contracts')
        },
        deleteTodo(id) {
            this.$removeConfirm('Crm/deleteTodo', {
                id: id
            }, this.refreshTable)
        },
        deleteSelected() {
            this.$removeConfirm('Crm/deleteTodos', this.selected.map(row => row.id), this.refreshTable)
        },
        sendMails() {
            const selected = this.selected.length || this.selectedSource != 'selected'
            this.$root.$children[0].openModal('email-modal', {
                type: selected ? 'crmTodos' : null,
                selectedData: selected ? this.getSelectedData('crmTodos') : null,
                multiple: true
            }, this.refreshTable, {width: '800px'})
        },
        openChangeAttribute() {
            this.$root.$children[0].openModal('change-attribute-modal', {
                type: 'customer',
                selectedData: this.getSelectedData('crmTodos'),
            }, this.refreshTable, {width: '800px'})
        },
        openCustomer(id) {
            this.$store.dispatch('Crm/getCustomerList', {
                ...this.getSelectedData('crmTodos'),
                'source':'filtered',
                'selectedSource':'filtered',
                'export':{
                    'ids': [],
                    'source':'filtered',
                }
            })
                .then((response) => {
                    this.$root.$children[0].openModal('customer-modal', {
                        customerId: id,
                        idsList: response.data.ids
                    }, this.refreshTable)
                })
        },
        getActions() {
            let actions = [
                {
                    title: this.$t('common.button.delete'),
                    icon: 'trash',
                    click: () => {
                        this.deleteSelected();
                    },
                },
                {
                    title: this.$t('common.button.send_email'),
                    icon: 'envelope',
                    click: () => {
                        this.sendMails()
                    }
                },

                {
                    title: this.$t('article.button.change_attribute'),
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    icon: 'list',
                    hide: !this.$root.hasAccess('customers', 'create'),
                    click: () => {
                        this.openChangeAttribute()
                    }
                },
                {
                    title: this.$t('common.button.close'),
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    icon: 'list',
                    click: () => {
                        this.loading = true

                        this.$store.dispatch('Crm/closeTodos', {
                            ...this.getSelectedData('crmTodos')
                        })
                            .then((response) => {
                                this.refreshTable()
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    },
                },
            ]

            this.getWorkflowTriggers()['customer'].forEach(trigger => {
                actions.push({
                    title: 'Trigger: ' + trigger.name,
                    icon: 'play',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    click: () => {
                        this.loading = true

                        this.$store.dispatch('Workflow/executeTrigger', {
                            id: trigger.id,
                            ...this.getSelectedData('crmTodos')
                        })
                            .then((response) => {
                                if (response.data.text) {
                                    this.$root.showMessage('Processing...', response.data.text, 'info')
                                    this.$root.$children[0].showJobsToast(response.data.result.batch_group)
                                }
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    },
                },);
            })

            return actions
        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.$root.$children[0].openModal('todo-modal', {id: this.$route.query.id}, null)
        }
    }
}
</script>